import React, {useState} from "react";
import Marquee, { Motion, randomIntFromInterval } from "react-marquee-slider";
import * as httpService from '../../services/HTTPService';

const MarqueeComponent = (props) => {
    const { children, velocity, minScale, resetAfterTries } = props;
    const [marqVelocity, setMarqVelocity] = useState(props.velocity);
    const [tokens, setTokens] = useState([]);
    const pause = () => {
        setMarqVelocity(0);
    }

    const resume = () => {
        setMarqVelocity(props.velocity);
    }

    const navigate = (url) => {
        window.open(url, '_blank').focus();
    }

    return (
        <div onMouseOver={() => pause()} onMouseLeave={() => resume()} className="marq-slider-container">
            <div></div>
            {props.source && props.source.length > 0 ? (
                <Marquee velocity={marqVelocity} minScale={minScale} resetAfterTries={resetAfterTries} >
                    {
                        props.source.map((el, i) => {
                            return (
                                <div key={i} className='tr-token-item flex-box' onClick={() => navigate(el.BuyLink)}>
                                    <span># {i + 1}</span>
                                    {el.Image && el.Image !== '' ? (
                                        //BuyLink
                                        <img src={el.Image} alt={el.Name} style={{borderRadius: '30px', height: '32px'}} />
                                    ) : (
                                        <div style={{borderRadius: '30px', height: '32px', width: '32px', background: "#fff"}}></div>
                                    )}
                                    <span>{el.Name}</span>
                                    {el.UpOrLow ? (
                                        <span className={el.UpOrLow === 'Low' ? 'st-down' : 'st-up'}></span>
                                    ) : null}
                                </div>
                            )
                        })
                    }
                </Marquee>
            ) : null}
        </div>
    )
}

export default MarqueeComponent;