import React, { useEffect, useState } from "react";

// required props: imgArray: array<src, href, alt>
// optional props: rotatorInterval: number

const BannerRotator = (props) => {
    const [imgSrc, setImgSrc] = useState(props.imgArray[0].src);
    let [imgIndex, setImgIndex] = useState(0);
    let [bannerLink, setBannerLink] = useState(props.imgArray[0].href);
    let intervalInMiliseconds = props.rotatorInterval || 10000;
    const rotateImg = (index) => {
        setImgSrc(props.imgArray[index].src);
        setBannerLink(props.imgArray[index].href);
    }

    useEffect(()=> {
        if(props.imgArray.length > 1) {
            // rotate banner images
            const interval = setInterval(() => {
                setImgIndex(imgIndex++)
                if(imgIndex >= props.imgArray.length) {
                    imgIndex = 0;
                    setImgIndex(0)
                    
                }
                rotateImg(imgIndex);
            }, intervalInMiliseconds);
            return () => clearInterval(interval);
        }
    }, [])

    return (
        <a href={bannerLink} target="_blank">
            <img alt={props.imgArray[imgIndex].alt} src={imgSrc} loading="lazy" />
        </a>
    )
}

export default BannerRotator;