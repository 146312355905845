import React, { useState, useEffect} from 'react';
import ReactGrid from "./React-Grid";
import * as httpService from '../services/HTTPService';
import ReactBootstrapModal from './UI/ReactBootstrapModal';
import Button from 'react-bootstrap/Button';
//import * as signalR from "@microsoft/signalr";
import MobileTableInfo from './UI/MobileTableInfo';
import { createGridColumns } from '../Utils/utils';
import WOW from 'wowjs';
//import SvgIcon from './UI/SVGIcon';

const CallsComponent = (props) => {
    const [rowData, setRowData] = useState(); // Set rowData to Array of Objects, one Object per Row
    const [errorMessage, setErrorMessage] = useState('');

    // Each Column Definition results in one Column.
    // {field: 'TokenName', filter: true, pinned: 'left'},

    //const hubUrl = httpService.baseUrl + "/ShillGuardHub";
    //const hubConnection = new signalR.HubConnectionBuilder().withUrl(hubUrl).build();

    // hubConnection.start().then(() => {
    //   console.log("Connected to SignalR hub");
    // }).catch((error) => {
    //   console.error("Error connecting to SignalR hub", error);
    // });
    
    // hubConnection.on("UpdateMarketCapToUP", (data) => {
    //   console.log("Received data from SignalR hub:", data);
    // });
     
    // hubConnection.on("UpdateMarketCapToDown", (data) => {
    //   console.log("Received data from SignalR hub:", data);
    // });

  //let numberColumn = {valueGetter: "node.rowIndex + 1", headerName: "#", width: 60, sortable: false, suppressSorting: true};
  
  
  let initialColumns = createGridColumns(['CryptoGroupImage', 'TokenName', 'Timestamp', 'TokenHash', 'BuyLinkCalls', 'BuyLink', 'TotalMarketCapIN', 'TotalMarketCap', 'ImpactOfCall', 'SuccessRate', 'FromCalltoPeak']);
  let [columnDefs, setColumnDefs] = useState(initialColumns);
  let extraColumns = createGridColumns(['PriceUSD', 'VolumeGenereted_h24', 'Holders', 'Source', 'FullTokenName']);
  const [modalShow, setModalShow] = React.useState(false);
  let [dataReq, setDataReq] = React.useState(false);

  const getData = () => {

    httpService.getAllSignals().then(res => {
      //console.log("data:", res );
      if(props && props.firstFive) {
        const arrSLice = res.slice(0, 5);
        setRowData(arrSLice);
      }
      else {
        setRowData(res);
      }
      // if(res) {
      //   res.forEach((el) => {
      //     if(el.CryptoGroupImage) {
      //       //console.log(el.CryptoGroupImage)
      //     }
      //   })
      // }
      
    })
    .catch(err => {
      if(err) {
        console.log(err)
        setErrorMessage(err);
      }
    })
  }

//   const BuyLinkHtml = (props) => {
//     return (
//         <div>
//             {props !== undefined ? (
//                 <div className="flex-box custom-cell">
//                     <div className="ellipsis accent-txt1" style={{'cursor': 'pointer'}}>
//                         <a className="ellipsis accent-txt1" href={props.data.BuyLink} style={{'textDecoration': 'none'}} target="_blank" rel="noreferrer">{props.data.BuyLink}</a>
//                     </div>
//                     <div title="Copy Link"><SvgIcon copyText={props.data.BuyLink} /></div>
//                 </div>
//             ) : null}
//         </div>
//     )
// }

  const modifyColumns = (data) => {
    const storageColumns = localStorage.getItem('homePageColumns');
    if(storageColumns) {
      if(data.length > 0) {
        setColumnDefs(data);
      }
    }
    else {
      setColumnDefs(initialColumns);
    }
    setModalShow(false);
  }

  useEffect(() => {
    // fetch('https://www.ag-grid.com/example-assets/row-data.json')
    // .then(result => result.json())
    // .then(rowData => setRowData(rowData))
    //localStorage.removeItem('homePageColumns')
    if(!dataReq) {
      setDataReq(true);
      dataReq = true;
      getData();
    }

    const storageColumns = localStorage.getItem('homePageColumns');
    if(storageColumns) {
      let additionalColumns = JSON.parse(storageColumns);
      //let columns = [...additionalColumns];
      const storageData = createGridColumns(additionalColumns)
      setColumnDefs(storageData);
    }
    else {
      
      setColumnDefs(columnDefs);
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      getData();
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="calls">
        {/* <h1>Markets Overview</h1> */}
        {!props.noHeader ? (
          <div className='desktop desktop-cust-btn' style={{"textAlign": "right"}}>
            <MobileTableInfo />
            <Button variant="primary" onClick={() => setModalShow(true)} style={{'marginBottom': '25px', 'verticalAlign': 'top'}}>
                <svg style={{"marginRight": "10px"}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" color="var(--c-color-gray-500)"><path d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM13 5V19H9V5H13ZM5 5H7V19H5V5ZM15 19V5H19V19H15Z" fill="currentColor"></path></svg>
                Customize
            </Button>
        </div>
        ) : null}
        <ReactBootstrapModal 
          show={modalShow} 
          onHide={() => setModalShow(false)} 
          columns={columnDefs} 
          initialColumns={initialColumns}
          modifycolumns={modifyColumns} 
          extraColumns={extraColumns}
          isHomePage={true}
        />
       <div className='call-gr'>
            <ReactGrid 
              tooltipInteraction={true} 
              columns={columnDefs} 
              tooltipshowdelay={0} 
              rowData={rowData} 
              errorMessage={errorMessage} 
              onRowSelected={true} 
              sortable={true} 
            />
        </div>
        {!props.noHeader ? (
          <div className='mob-900 cutomize-mob'>
            <MobileTableInfo />
            <Button variant="primary" onClick={() => setModalShow(true)} style={{'marginBottom': '25px', 'verticalAlign': 'top'}}>
                <svg style={{"marginRight": "10px"}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" color="var(--c-color-gray-500)"><path d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM13 5V19H9V5H13ZM5 5H7V19H5V5ZM15 19V5H19V19H15Z" fill="currentColor"></path></svg>
                Customize
            </Button>
        </div>
        ) : null}
    </div>
  )
}

export default React.memo(CallsComponent);