export const headerSocialLinksArray = [
    {
        link: 'https://twitter.com/shillguard?s=21&t=rXEJZ-LoBGDflkBdm4XbHA',
        icon: '/img/social/header-social/X_20x20.svg',
        class: 'tw-link',
        name: 'Twitter',
        text: 'Follow us on Twitter to stay in the loop with real-time crypto trends, Shillguard news, and engaging discussions.',
    },
    {
        link: 'https://t.me/ShillGuard',
        icon: '/img/social/header-social/Telegram_20x20.svg',
        class: 'tel-link',
        name: 'Telegram',
        text: 'Connect with us on Telegram, your go-to hub for crypto calls, insightful discussions, and Shillguard announcements',
    },
    {
        link: 'https://discord.com/invite/5sXENvh3Qu',
        icon: '/img/social/header-social/Discord_20x20.svg',
        class: 'dis-link',
        name: 'Discord',
        text: 'Join our Discord community for live chats, AMAs, and vibrant discussions with fellow crypto enthusiasts.',
    },
    {
        link: 'https://instagram.com/shillguard_official?igshid=YmMyMTA2M2Y=',
        icon: '/img/social/header-social/IG_20x20.svg',
        class: 'in-link',
        name: 'Instagram',
        text: 'Follow us on Instagram for a visual journey into the Shillguard ecosystem.',
    },
]

export const footerSocialExtraLinks = [
    {
        link: 'https://medium.com/@shillguard.official/shillguard-is-a-game-changer-4ca118b5745e',
        icon: '/img/social/header-social/medium-icon.png',
        class: 'medium-link',
        name: 'Medium',
        text: '',
    },
    {
        link: 'https://www.tiktok.com/@shillguard?_t=8eJZX1fRULr',
        icon: '/img/social/header-social/tiktok-icon.png',
        class: 'medium-link',
        name: 'TikTok',
        text: '',
    },
    {
        link: 'https://www.youtube.com/channel/UCaI5uSHSioqfNEppiQUyqcA',
        icon: '/img/social/header-social/you-tube-icon.png',
        class: 'medium-link',
        name: 'YouTube',
        text: '',
    }
]

export const socialIconUrl = (socialMediaName) => {
    let iconUrl = "";
    headerSocialLinksArray.forEach((item) => {
        if(item.name === socialMediaName) {
            iconUrl = item.icon;
        }
    })
    return iconUrl;
}