export const aboutData = [
    {
        name: "Our Token: SGT",
        imageUrl: "/img/Our-Token-SGT.png",
        text: "$SGT, our native token, is the heartbeat of Shillguard.com. Crafted on the Ethereum blockchain with meticulous care, it operates on a deﬂationary model that fuels the ongoing evolution of our platform.",
        buttonLink: "/ourtoken"
    },
    {
        name: "Staking Pool",
        imageUrl: "/img/Staking-Pool.png",
        text: "Our Staking Pools are more than a way to earn rewards; they're a testament to trust and commitment. By staking your $SGT, you actively contribute to the stability of Shillguard.com.",
        buttonLink: "/staking"
    },
    {
        name: "Roadmap",
        imageUrl: "/img/RoadMap.png",
        text: "Embark on a journey with our roadmap, charting the course for the future of Shillguard.com. Here's a glimpse of what lies ahead!",
        buttonLink: "/roadmap"
    },
    {
        name: "Crypto News",
        imageUrl: "/img/Crypto-News.png",
        text: "Our Crypto News section is your gateway to staying informed and making smarter trading decisions. Explore the latest updates, market insights, and breaking news directly impacting the crypto world.",
        buttonLink: "/news"
    },
    {
        name: "Team",
        imageUrl: "/img/Team.png",
        text: "Discover the dedicated minds fueling Shillguard.com – a doxxed team committed to shaping a more informed and trustworthy crypto landscape. Get to know the faces driving our vision and join us on this exciting journey!",
        buttonLink: "/team"
    },
    {
        name: "Partners",
        imageUrl: "/img/Partners.png",
        text: "Explore the Partners section to uncover the strong alliances supporting Shillguard.com. Each partnership is a testament to our commitment to growth, innovation, and community building.",
        buttonLink: "/partners"
    },
];