import React, { useState } from "react";
import MainNavLinks from "./MainNav";

const LeftSideDesktopNav = () => {
    const [expandMenuClass, setExpandMenuClass] = useState('')
    const mouseOver = () => {
        setExpandMenuClass('expand-left-nav');
    }
    const mouseOut = () => {
        setExpandMenuClass('');
    }

    return (
        <div className={`desktop-nav left-side-nav ${expandMenuClass}`} onMouseOver={mouseOver} onMouseOut={mouseOut}>
            {/* <button className="sc-guDLey kLTlmV burger-btn"><div></div><div></div></button> */}
            {/* <div className="flex-box"><span>ShillGuard</span></div> */}
            <MainNavLinks />
        </div>
    )
}

export default LeftSideDesktopNav;