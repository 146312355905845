
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { tsvParse, csvParse } from  "d3-dsv";
import { timeParse } from "d3-time-format";

import { scaleTime } from "d3-scale";
import { curveMonotoneX } from "d3-shape";

import { ChartCanvas, Chart } from "react-stockcharts";
import { AreaSeries } from "react-stockcharts/lib/series";
import { XAxis, YAxis } from "react-stockcharts/lib/axes";
import { fitWidth } from "react-stockcharts/lib/helper";
import { createVerticalLinearGradient, hexToRGBA } from "react-stockcharts/lib/utils";
import { getData } from "./ChartUtils";
import { getData2 } from "./ChartUtils";

const canvasGradient = createVerticalLinearGradient([
	{ stop: 0, color: hexToRGBA("#b5d0ff", 0.2) },
	{ stop: 0.7, color: hexToRGBA("#6fa4fc", 0.4) },
	{ stop: 1, color: hexToRGBA("#4286f4", 0.8) },
]);

let AreaChart = (props) => {
    const { type, width, ratio } = props;
    const [data, setData] = useState([]); 

    useEffect(() => {
        //setData(getData());
        //setData(getData2);
    }, [])

    //headings: 
    // date	open	high	low	close	volume	split	dividend	absoluteChange	percentChange
    const row1 = {
        "date": new Date('2012-04-05T00:00:00.000Z'),
        "open": 73.5,
        "high": 79.54,
        "low": 72.22,
        "close": 74.31,
        "volume": 553642
    }
    const row2 = {
        "date": new Date('2012-05-05T00:00:00.000Z'),
        "open": 74.5,
        "high": 74.54,
        "low": 74.22,
        "close": 74.31,
        "volume": 553642
    }
    const jsonData = [row1, row2];

// grab the column headings (separated by tabs)
const headings = Object.keys(jsonData[0]).join('\t');

// iterate over the data
const rows = jsonData.reduce((acc, c) => {
  
  // for each row object get its values and add tabs between them
  // then add them as a new array to the outgoing array
  return acc.concat([Object.values(c).join('\t')]);

// finally joining each row with a line break
}, []).join('\n');

// display the result (here with `innerText` because
// `textContent` doesn't recognise styling)
const tsfData = `${headings}\n${rows}`;
// console.log(tsfData)
// function parseData(parse) {
// 	return function(d) {
// 		d.date = parse(d.date);
// 		d.open = +d.open;
// 		d.high = +d.high;
// 		d.low = +d.low;
// 		d.close = +d.close;scans
// 		d.volume = +d.volume;

// 		return d;
// 	};
// }
// const parseDate = timeParse("%Y-%m-%d");
// const d = tsvParse(tsfData, parseData(parseDate))

    if(data && data.length > 0) {
        return (
            // <ChartCanvas ratio={1} width={100} height={400}
            //     margin={{ left: 50, right: 50, top: 10, bottom: 30 }}
            //     seriesName="MSFT"
            //     data={data} type={type}
            //     xAccessor={d => d && d.date ? d.date : new Date()}
            //     xScale={scaleTime()}
            //     xExtents={[new Date(2011, 0, 1), new Date(2013, 0, 2)]}
            // >
            //     <Chart id={0} yExtents={d => d.close}>
            //         <defs>
            //             <linearGradient id="MyGradient" x1="0" y1="100%" x2="0" y2="0%">
            //                 <stop offset="0%" stopColor="#b5d0ff" stopOpacity={0.2} />
            //                 <stop offset="70%" stopColor="#6fa4fc" stopOpacity={0.4} />
            //                 <stop offset="100%"  stopColor="#4286f4" stopOpacity={0.8} />
            //             </linearGradient>
            //         </defs>
            //         <XAxis axisAt="bottom" orient="bottom" ticks={6}/>
            //         <YAxis axisAt="left" orient="left" />
            //         <AreaSeries
            //             yAccessor={d => d.close}
            //             fill="url(#MyGradient)"
            //             strokeWidth={2}
            //             interpolation={curveMonotoneX}
            //             canvasGradient={canvasGradient}
            //         />
            //     </Chart>
            // </ChartCanvas>
            <div></div>
        );
    }
    else {
        return (
            <div></div>
        )
    }
}



AreaChart.defaultProps = {
	type: "svg",
};
//AreaChart = fitWidth(AreaChart);

export default AreaChart;