import axios from 'axios';
// production url
let url = 'https://shillguard-001-site2.etempurl.com';
// development url
//https://shillguard-001-site2.etempurl.com
// if(window && (window.location.href.indexOf('shillguard.revolutionlab.bg') !== -1)) {
//     url = 'https://revolutionlab-001-site7.itempurl.com';
// }
export const baseUrl = url;
export const getAllSignals = () => {
    const url = baseUrl + '/signals/GetAllSignals';
    return axios.get(url).then(res => res.data);
}

export const getSingleItem = (NameOfCallGroup) => {
    const url = baseUrl + `/signals/GetAllSignalsByCallGroupName/${NameOfCallGroup}`
    return axios.get(url).then(res => res.data);
}

export const getTopSignalsByCallGroup = (NameOfCallGroup) => {
    const url = baseUrl + `/signals/GetAllSignalsByCallGroupName/${NameOfCallGroup}/isTop3=true`
    return axios.get(url).then((res) => {
        return res.data;
    })
    .catch((err) => {
        // The value 'isTop3=true' is not valid.
        console.log(err)
    });
}

export const getTopFiveCallers = () => {
    const url = baseUrl + `/signals/GetTop5Callers`;
    return axios.get(url).then((res) => {
        return res.data;
    })
    .catch((err) => {
        console.log(err)
    });
}

export const getTopFiveSignals = () => {
    const url = baseUrl + `/signals/GetTop5Signals`;
    return axios.get(url).then((res) => {
        return res.data;
    })
    .catch((err) => {
        console.log(err)
    });
}

export const getAllNews = () => {
    const url = baseUrl + `/news/GetAllNewsFromDb`;
    return axios.get(url).then((res) => {
        return res.data;
    })
    .catch((err) => {
        console.log(err)
    });
}

export const getTopTrendingTokens = () => {
    const url = baseUrl + `/signals/GetTopTrendingTokens`;
    return axios.get(url).then((res) => {
        return res.data;
    })
    .catch((err) => {
        console.log(err)
    });
}


export const loadNewsItems = (skip, pageSize) => {
    const url = baseUrl + `/news/GetAllNewsFromDb/${skip}/${pageSize}`
    return axios.get(url).then(res => res.data);
}

export const getChartData = (groupName) => {
    const url = baseUrl + `/signals/GetStats/${groupName}`
    return axios.get(url, { validateStatus: false }).then(res => res);
}

export const getTokenStatSearch = (string) => {
    const url = baseUrl + `/signals/GetTokenStat/${string}`
    return axios.get(url, { validateStatus: false }).then(res => res.data);
}

export const getAllSignalsSearch = () => {
    const url = baseUrl + `/signals/AutocompleteSignals`
    return axios.get(url, { validateStatus: false }).then(res => res.data);
}