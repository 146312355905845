import React from "react"
import styled from 'styled-components';
import MainNavLinks from "./MainNav";
import HeaderSocialLinks from "./HeaderSocialLinks";
import { headerSocialLinksArray } from "../data/HeaderSocialLinksData";

const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  background: #fff;
  transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(120%)'};
  text-align: left;
  padding-top: 50px;
  border-radius: 20px;
  position: absolute;
  z-index: 99;
  top: 20px;
  right: 20px;
  transition: transform 0.3s ease-in-out;

  @media (max-width: 900px) {
      width: auto;
      text-align: center;
      padding: 20px
    }

  a {
    font-size: 18px;
    font-weight: bold;
    color: #0D0C1D;
    text-decoration: none;
    transition: color 0.3s linear;

    @media (max-width: 576px) {
      text-align: center;
    }

    &:hover {
      color: #343078;
    }
  }
`

const MobileMenu = ({ open }) => {
  return (
    <StyledMenu open={open}>
        <MainNavLinks />
        <HeaderSocialLinks links={headerSocialLinksArray} />
    </StyledMenu>
  )
}

const StyledBurger = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  position: relative;
  z-index: 999;

  &:focus {
    outline: none;
  }

  div {
    width: 2rem;
    height: 0.25rem;
    background: #0D0C1D;
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0)'};
    }

    :nth-child(2) {
      opacity: ${({ open }) => open ? '0' : '1'};
      transform: ${({ open }) => open ? 'translateX(20px)' : 'translateX(0)'};
    }

    :nth-child(3) {
      transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
    }
  }
`

const Burger = ({ open, setOpen }) => {
  return (
    <StyledBurger open={open} onClick={() => setOpen(!open)} className="burger-btn">
      <div />
      <div />
    </StyledBurger>
  )
}

const MobileNav = () => {
    const [open, setOpen] = React.useState(false);
    const node = React.useRef();
    return (
        <div ref={node} className="mobile-nav">
            <Burger open={open} setOpen={setOpen}  />
            <MobileMenu open={open} setOpen={setOpen} />
        </div>
    )
}

export default MobileNav;