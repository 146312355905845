import React, { useState, useEffect, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { getDefaultTheme } from '../../Utils/utils';
import { createGridColumns } from '../../Utils/utils';
import SearchComponent from './SearchComponent';
import * as httpService from '../../services/HTTPService';

const ReactBootstrapSearchModal = (props) => {
    const mockData = [
        { id: 1, title: "BagCalls", description: "The OG of OGs. Main - @Bagcalls Gambles - @Bagscasino TRIPLE CHECK MY USERNAME BELOW: @bitbaggerr" },
        { id: 2, title: "Maybach Calls", description: "Telegram Influencer!Dm for promo:" },
        { id: 3, title: "Zorro Calls Gems of X1000", description: "I'm promoting new gems ,BSC , Arbitrum, ETH project and etc. Diamond hands only 💎. Influencer / Web Developer/cmc/dextools/billboards etc." }
    ];

    const [resData, setResData] = useState({});
    const [loaded, setLoaded] = useState(false);
    const innerRef = useRef();
    // const sendRequest = (searchParam) => {
    //   httpService.getTokenStatSearch(searchParam).then((res) => {
    //       if(res) {
    //           console.log(res);
    //           setResData(res);
    //       }
    //   })
        
    // }

    const onshow = () => {
      if(loaded) {
        innerRef.current.focus()
      }
    }
    

    useEffect(() => {
      setLoaded(true)
  }, []);

    return (
        <Modal
          disableRestoreFocus 
          autoFocus={false}
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className={getDefaultTheme() === 'light' ? 'light-modal full-screen-modal' : 'dark-modal full-screen-modal'}
          onShow={onshow}
        >
          {/* <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Search
            </Modal.Title>
            
          </Modal.Header> */}
          <Modal.Body>
          <Button onClick={props.onHide} className='left-close'>X</Button>
            {/* <SearchComponent data={props.signalsArrray} readOnly={false} autoFocus={true} sendSearchParameter={sendRequest} /> */}
            <SearchComponent data={props.signalsArrray} readOnly={false} autoFocus={props.focus} ref={innerRef}/>
          </Modal.Body>
          {/* <Modal.Footer>
            <Button onClick={props.onHide} variant="secondary">Cancel</Button>
            <Button onClick={onApply}>Apply</Button>
          </Modal.Footer> */}
        </Modal>
      );
}

export default ReactBootstrapSearchModal;