

import { tsvParse, csvParse } from  "d3-dsv";
import { timeParse } from "d3-time-format";

function parseData(parse) {
	return function(d) {
		d.date = parse(d.date);
		d.open = +d.open;
		d.high = +d.high;
		d.low = +d.low;
		d.close = +d.close;
		d.volume = +d.volume;

		return d;
	};
}

const parseDate = timeParse("%Y-%m-%d");

export function getData() {
	const promiseMSFT = fetch("https://cdn.rawgit.com/rrag/react-stockcharts/master/docs/data/MSFT.tsv")
		.then(response => response.text())
		.then(data => tsvParse(data, parseData(parseDate)))
	return promiseMSFT;
}

export function getData2() {
        const row1 = {
            "date": new Date('2012-05-05T00:00:00.000Z'),
            "open": 73.5,
            "high": 79.54,
            "low": 72.22,
            "close": 74.31,
            "volume": 553642
        }
        const row2 = {
            "date": new Date('2012-06-05T00:00:00.000Z'),
            "open": 74.5,
            "high": 74.54,
            "low": 74.22,
            "close": 74.31,
            "volume": 553642
        }
        const jsonData = [row1, row2];
    
    // grab the column headings (separated by tabs)
    const headings = Object.keys(jsonData[0]).join('\t');
    
    // iterate over the data
    const rows = jsonData.reduce((acc, c) => {
      
      // for each row object get its values and add tabs between them
      // then add them as a new array to the outgoing array
      return acc.concat([Object.values(c).join('\t')]);
    
    // finally joining each row with a line break
    }, []).join('\n');
    
    // display the result (here with `innerText` because
    // `textContent` doesn't recognise styling)
    const tsfData = `${headings}\n${rows}`;
    //console.log(tsfData)
    const d = tsvParse(tsfData, parseData(parseDate))
	return jsonData;
}
