import React from "react";
import HeaderSocialLinks from "./HeaderSocialLinks";
import { headerSocialLinksArray } from "../data/HeaderSocialLinksData";
import { footerSocialExtraLinks } from "../data/HeaderSocialLinksData";

export const LegalAdvisePageHtml = () => {
    return (
        <div className="footer-page">
            <h2>Legal Advice</h2>
            <div className="flex-box footer-page-box">
                <div className="rm-main">
                    <div>
                        <h3>1. No Financial or Legal Advice:</h3>
                        <p>
                            <span>Informational Purpose: </span> 
                            The information provided on this website is for informational purposes only and should not be considered as ﬁnancial or legal advice.
                        </p>
                        <p>
                            <span>Not a Substitute:</span> 
                            It is not a substitute for consulting with a qualiﬁed ﬁnancial advisor or legal professional.
                        </p>
                    </div>
                    <div>
                        <h3>2. No Liability for Financial Decisions:</h3>
                        <p>
                            <span>Financial Responsibility: </span> 
                            We are not ﬁnancial consultants, and  any decisions made based on the information provided on this website are your own and at your own risk.
                        </p>
                        <p>
                            <span>Losses Disclaimer:</span> 
                            We are not liable for any ﬁnancial losses incurred from investments, trades, or transactions related to cryptocurrencies or tokens.
                        </p>
                    </div>
                </div>
                <div className="rm-main">
                    <div>
                        <h3>3. Seek Professional Advice:</h3>
                        <p>
                            <span>Consult a Professional: </span> 
                            Users should seek advice from a qualiﬁed ﬁnancial advisor or legal professional before making any ﬁnancial decisions related to cryptocurrencies or tokens.
                        </p>
                    </div>
                    <div>
                        <h3>4. Accuracy and Reliability:</h3>
                        <p>
                            <span>Consult a Professional: </span> 
                            Users should seek advice from a qualiﬁed ﬁnancial advisor or legal professional before making any ﬁnancial decisions related to cryptocurrencies or tokens.
                        </p>
                        <p>
                            <span>Use at Your Own Risk: </span> 
                            Users use the information at their own risk and discretion.
                        </p>
                    </div>
                    <div>
                        <h3>5. Jurisdiction:</h3>
                        <p>
                            <span>Applicable Law: </span> 
                            Any legal disputes related to this website and its information will be subject to the laws of EU.
                        </p>
                        <p>
                            <span>Exclusive Jurisdiction: </span> 
                            The exclusive jurisdiction for any disputes shall be the courts in EU.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const TermsOfusePageHtml = () => {
    return (
        <div className="footer-page">
            <h2>Terms of Use</h2>
            <div className="flex-box footer-page-box">
                <div className="rm-main">
                    <div>
                        <h3>1.Introduction:</h3>
                        <p>
                            <span>Agreement to Terms: </span> 
                            Welcome to Shillguard. These Terms of Use constitute a legally binding agreement made between you, 
                            whether personally or on behalf of an entity, and Shillguard, regarding your use of our website.
                        </p>
                        <p>
                            <span>Acceptance of Terms: </span> 
                            By accessing and using our website, you agree to be bound by these Terms of Use and our Privacy Policy. 
                            If you do not agree with these terms, you may not use our website.
                        </p>
                    </div>
                    <div>
                        <h3>2.Intellectual Property:</h3>
                        <p>
                            <span>Ownership: </span> 
                            All content and materials on this website, including text, graphics, logos, images, and software, 
                            are the property of Shillguard and are protected by copyright and other intellectual property laws.
                        </p>
                        <p>
                            <span>Limited License: </span> 
                            Users are granted a limited, non-exclusive, revocable license to access and use the content for personal, 
                            non-commercial purposes only.
                        </p>
                    </div>
                    <div>
                        <h3>3.User Conduct:</h3>
                        <p>
                            <span>Compliance with Laws: </span> 
                            Users must use our website in compliance with all applicable laws and regulations.
                        </p>
                        <p>
                            <span>Prohibited Activities: </span> 
                            Users must not engage in any activities that violate the law, infringe on the rights of others, 
                            or interfere with the functioning of the website.
                        </p>
                    </div>
                </div>
                <div className="rm-main">
                    <div>
                        <h3>4.Privacy:</h3>
                        <p>
                            <span>Privacy Policy: </span> 
                            Our Privacy Policy outlines how we collect, use, and protect your personal information. 
                            By using our website, you agree to our Privacy Policy.
                        </p>
                    </div>
                    <div>
                        <h3>5.Limitation of Liability:</h3>
                        <p>
                            <span>No Liability: </span> 
                            We are not liable for any damages or losses, including indirect, consequential, exemplary, 
                            or punitive damages, arising from the use of our website.
                        </p>
                    </div>
                    <div>
                        <h3>6.Termination:</h3>
                        <p>
                            <span>Termination Rights: </span> 
                            We reserve the right to terminate or suspend your access to our website without prior notice for any reason, 
                            including violation of these Terms of Use.
                        </p>
                    </div>
                    <div>
                        <h3>7.Governing Law:</h3>
                        <p>
                            <span>Applicable Law: </span> 
                            These Terms of Use are governed by the laws of EU.
                        </p>
                        <p>
                            <span>Jurisdiction: </span> 
                            Any legal actions or disputes arising from these terms shall be subject to the exclusive jurisdiction of the courts in EU.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const ContactsPageHtml = () => {
    const links = headerSocialLinksArray.concat(footerSocialExtraLinks);
    console.log('links: ', links)
    return (
        <div className="footer-page">
            <h2>Contact Us</h2>
            <div>
                <HeaderSocialLinks links={links} />
            </div>
            <div>
                <div className="flex-box mails">
                    <span><a href="mailto:ofﬁcial@shillguard.com">ofﬁcial@shillguard.com</a></span>
                    <span><a href="mailto:marketing@shillguard.com">marketing@shillguard.com</a></span>
                    <span><a href="mailto:support@shillguard.com">support@shillguard.com</a></span>
                </div>
            </div>
        </div>
    )
}