export const partnersArrayData = [
    {
        partnerLogoUrl: '/img/partners/shillguard.png',
        partnerName: 'ShillGuard',
        partnerText: "Let's Build the Future Together! At Shillguard, we believe in the power of partnerships to revolutionize the crypto landscape. If you share our passion for empowering the crypto community and fostering innovation, we invite you to collaborate with us. Whether you're a forward-thinking organization, a dynamic call group, or an influential individual, let's unite to redefine cryptocurrency. We're open to partnerships that drive creativity, growth, and meaningful connections. Reach out today, and let's shape the future of crypto hand in hand. Together, we can achieve more and make a lasting impact.",
        website: "https://shillguard.com/",
        partnerContacts: [
            {
                link: 'https://t.me/ShillGuard',
                icon: '/img/social/header-social/Telegram_20x20.svg',
                whiteIcon: '/img/social/header-social/Telegram_20x20_white.svg',
                class: 'tel-link',
                name: 'Telegram',
                text: 'Connect with us on Telegram, your go-to hub for crypto calls, insightful discussions, and Shillguard announcements',
            },
            {
                link: 'https://twitter.com/shillguard?s=21&t=rXEJZ-LoBGDflkBdm4XbHA',
                icon: '/img/social/header-social/X_20x20.svg',
                whiteIcon: '/img/social/header-social/X_20x20_white.svg',
                class: 'tw-link',
                name: 'Twitter',
                text: 'Follow us on Twitter to stay in the loop with real-time crypto trends, Shillguard news, and engaging discussions.',
            },
            {
                link: 'https://discord.com/invite/5sXENvh3Qu',
                icon: '/img/social/header-social/Discord_20x20.svg',
                whiteIcon: '/img/social/header-social/Discord_20x20_white.svg',
                class: 'dis-link',
                name: 'Discord',
                text: 'Join our Discord community for live chats, AMAs, and vibrant discussions with fellow crypto enthusiasts.',
            },
            {
                link: 'https://instagram.com/shillguard_official?igshid=YmMyMTA2M2Y=',
                icon: '/img/social/header-social/IG_20x20.svg',
                whiteIcon: '/img/social/header-social/IG_20x20_white.svg',
                class: 'in-link',
                name: 'Instagram',
                text: 'Follow us on Instagram for a visual journey into the Shillguard ecosystem.',
            },
            
        ]
    },
    {
        partnerLogoUrl: '/img/partners/Coinsult---Audits-&-KYC.png',
        partnerName: 'Coinsult - Auditing Excellence',
        partnerText: "Meet our partner, Coinsult - Audits & KYC, an industry leader in smart contract security. They utilize advanced manual checks and AI technology to secure, develop, and vigilantly monitor smart contracts. With a proven track record of auditing 1000+ blockchain projects, Coinsult ensures the utmost security and reliability. Their unwavering dedication and commitment to the crypto community have earned them a stellar reputation, making them a trusted name in the industry. Our partnership guarantees a secure and transparent crypto environment, all thanks to their outstanding work. Together, we are shaping a safer and more trustworthy crypto landscape, stay tuned for exciting developments as our partnership with Coinsult continues to evolve.",
        website: "https://coinsult.net/",
        partnerContacts: [
            {
        
                link: 'https://t.me/coinsult_tg',
                icon: '/img/social/header-social/Telegram_20x20.svg',
                whiteIcon: '/img/social/header-social/Telegram_20x20_white.svg',
                class: 'tel-link',
                name: 'Telegram',
            },
            {
                
                link: 'https://twitter.com/coinsultaudits',
                icon: '/img/social/header-social/X_20x20.svg',
                whiteIcon: '/img/social/header-social/X_20x20_white.svg',
                class: 'tw-link',
                name: 'Twitter',
            }
        ]
    },
    {
        partnerLogoUrl: '/img/partners/Revolution-Lab.png',
        partnerName: 'Revolution Lab: Your Software Solutions Partner',
        partnerText: "We specialize in custom software development and IT outsourcing. With a track record of 254+ successful projects and 157+ satisfied clients, we offer expert guidance for startups, time and cost savings, and endless business possibilities. Our agile approach ensures top-notch solutions. Join us for cutting-edge technology and a commitment to your success, particularly in the crypto industry.",
        website: "https://revolutionlab.bg/",
        partnerContacts: [
            {
                link: 'https://t.me/ggrudev',
                icon: '/img/social/header-social/Telegram_20x20.svg',
                whiteIcon: '/img/social/header-social/Telegram_20x20_white.svg',
                class: 'tel-link',
                name: 'Telegram',
            },
            {
                link: "https://x.com/RevolutionLabBg",
                icon: '/img/social/header-social/X_20x20.svg',
                whiteIcon: '/img/social/header-social/X_20x20_white.svg',
                class: 'tw-link',
                name: 'Twitter',
            }
            
        ]
    },
    {
        partnerLogoUrl: '/img/partners/devai-txt.png',
        partnerName: 'DEVAI: A Seamless Solution for Solidity Challenges! ',
        partnerText: "Empower your smart contract deployment experience with DEVAI—an innovative solution to Solidity contract generation challenges! 💡 Our Telegram bot and Web App seamlessly create flawless smart contracts across multiple chains. Customize your contracts by setting parameters, taxes, and more without any coding knowledge. DEVAI offers a user-friendly interface for deploying, verifying, adding liquidity, locking or burning LP, enabling trading, and renouncing ownership. Enhance security and reduce risks in your deployments with DEVAI!",
        website: "https://www.contractdevai.com/",
        partnerContacts: [
            {
                link: 'https://t.me/contractdevai',
                icon: '/img/social/header-social/Telegram_20x20.svg',
                whiteIcon: '/img/social/header-social/Telegram_20x20_white.svg',
                class: 'tel-link',
                name: 'Telegram',
                text: 'Connect with us on Telegram, your go-to hub for crypto calls, insightful discussions, and Shillguard announcements',
            },
            {
                link: 'https://x.com/contractdevai',
                icon: '/img/social/header-social/X_20x20.svg',
                whiteIcon: '/img/social/header-social/X_20x20_white.svg',
                class: 'tw-link',
                name: 'Twitter',
                text: 'Follow us on Twitter to stay in the loop with real-time crypto trends, Shillguard news, and engaging discussions.',
            }
            
        ]
    },
    {
        partnerLogoUrl: '/img/partners/trend.cbef516a.png',
        partnerName: 'TrendAppend: Video Sharing, Marketplace, and Crypto Payments ',
        partnerText: "Introducing TrendAppend, a groundbreaking platform at the forefront of crypto innovation. Within the expansive crypto space, TrendAppend operates as a multifaceted entity, seamlessly blending video sharing, marketplace services, and a robust crypto payments platform. It serves as a dynamic space for sharing engaging crypto-related videos, fostering collaboration between projects in its marketplace, and providing a secure foundation for seamless transactions. As a strategic partner, TrendAppend enriches our ecosystem with its diverse offerings, contributing to the continuous evolution and advancement of the crypto landscape. ",
        website: "https://token.trend.app/",
        partnerContacts: [
            {
                link: 'https://t.me/trendappend',
                icon: '/img/social/header-social/Telegram_20x20.svg',
                whiteIcon: '/img/social/header-social/Telegram_20x20_white.svg',
                class: 'tel-link',
                name: 'Telegram',
                text: 'Connect with us on Telegram, your go-to hub for crypto calls, insightful discussions, and Shillguard announcements',
            },
            {
                link: 'https://twitter.com/TrendAppend',
                icon: '/img/social/header-social/X_20x20.svg',
                whiteIcon: '/img/social/header-social/X_20x20_white.svg',
                class: 'tw-link',
                name: 'Twitter',
                text: 'Follow us on Twitter to stay in the loop with real-time crypto trends, Shillguard news, and engaging discussions.',
            }
            
        ]
    },
    // {
    //     partnerLogoUrl: '/img/partners/unidexai.png',
    //     partnerName: 'UniDexAI: Trading with Cutting-Edge AI Research and Decentralized Features ',
    //     partnerText: "UniDexAI stands as a pioneering web3 trading platform that leverages advanced AI research to elevate the Ethereum token trading experience. Its feature-rich environment includes Automated Pair Filtering, AI-Powered Contract Auditing, a Comprehensive Trading Interface, New Pair Filtering, Decentralized Trading, and an Anti-MEV Option. This collaboration brings an innovative and seamless trading experience to our users, enhancing the capabilities of both platforms. ",
    //     website: "https://unidexai.xyz/",
    //     partnerContacts: [
    //         {
    //             link: 'https://t.me/UniDexAI',
    //             icon: '/img/social/header-social/Telegram_20x20.svg',
    //             whiteIcon: '/img/social/header-social/Telegram_20x20_white.svg',
    //             class: 'tel-link',
    //             name: 'Telegram',
    //             text: 'Connect with us on Telegram, your go-to hub for crypto calls, insightful discussions, and Shillguard announcements',
    //         },
    //         {
    //             link: 'https://twitter.com/unidexai',
    //             icon: '/img/social/header-social/X_20x20.svg',
    //             whiteIcon: '/img/social/header-social/X_20x20_white.svg',
    //             class: 'tw-link',
    //             name: 'Twitter',
    //             text: 'Follow us on Twitter to stay in the loop with real-time crypto trends, Shillguard news, and engaging discussions.',
    //         }
            
    //     ]
    // },
    {
        partnerLogoUrl: '/img/partners/sectbot.svg',
        partnerName: 'SectBot: Defi Calls and Group Insights ',
        partnerText: 'SectBot introduces a groundbreaking subscription service, granting direct access to real-time calls and group insights from top Defi callers. With a focus on providing valuable information for smarter investments, SectBot extends its reach to multiple private Defi groups, showcasing impressive ROI and engagement during its Alpha phase. The first project to gamify the "calling" of tokens among Telegram and Discord groups.  ',
        website: "https://www.sectbot.com/",
        partnerContacts: [
            {
                link: 'https://t.me/SectTokenPortal',
                icon: '/img/social/header-social/Telegram_20x20.svg',
                whiteIcon: '/img/social/header-social/Telegram_20x20_white.svg',
                class: 'tel-link',
                name: 'Telegram',
                text: 'Connect with us on Telegram, your go-to hub for crypto calls, insightful discussions, and Shillguard announcements',
            },
            {
                link: 'https://twitter.com/thesectbot',
                icon: '/img/social/header-social/X_20x20.svg',
                whiteIcon: '/img/social/header-social/X_20x20_white.svg',
                class: 'tw-link',
                name: 'Twitter',
                text: 'Follow us on Twitter to stay in the loop with real-time crypto trends, Shillguard news, and engaging discussions.',
            }
            
        ]
    }
]