import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const HomePageCarousel = () => {

    const homeCarouselItems = [
        // {
        //     leftPart: {
        //         title: "Our Token: SGT",
        //         text: "$SGT, our native token, is the heartbeat of Shillguard.com. Crafted on the Ethereum blockchain with meticulous care, it operates on a deﬂationary model that fuels the ongoing evolution of our platform.",
        //         btnText: "Learn more",
        //         btnUrl: "/ourtoken"
        //     },
        //     rightPart: {
        //         imgUrl: "/img/Our-Token-SGT.png"
        //     }
        // },
        // {
        //     leftPart: {
        //         title: "Roadmap",
        //         text: "Embark on a journey with our roadmap, charting the course for the future of Shillguard.com. Here's a glimpse of what lies ahead!",
        //         btnText: "Learn more",
        //         btnUrl: "/roadmap"
        //     },
        //     rightPart: {
        //         imgUrl: "/img/RoadMap.png"
        //     }
        // },
        // {
        //     leftPart: {
        //         title: "Crypto News",
        //         text: "Our Crypto News section is your gateway to staying informed and making smarter trading decisions. Explore the latest updates, market insights, and breaking news directly impacting the crypto world.",
        //         btnText: "Learn more",
        //         btnUrl: "/news"
        //     },
        //     rightPart: {
        //         imgUrl: "/img/Crypto-News.png"
        //     }
        // },
        {
            leftPart: {
                title: "Telegram App",
                text: "ShillGuardApp is your essential app for DeFi analysis, market insights, and real-time signal tracking.",
                btnText: "Learn more",
                btnUrl: "/TelegramApp"
            },
            rightPart: {
                imgUrl: "/img/App-SGT.png"
            }
        },
        {
            leftPart: {
                title: "Telegram Bots",
                text: "ShillGuard is deeply committed to the development of Telegram, recognizing it as a crucial platform for our future.",
                btnText: "Learn more",
                btnUrl: "/TelegramBots"
            },
            rightPart: {
                imgUrl: "/img/Bot-SGT.png"
            }
        },
        {
            leftPart: {
                title: "Do you want to see your advertisement here?",
                mobileTitle: "Advertise here!",
                text: "Maximize your visibility in the crypto space! Secure premium advertising space on ShillGuard.com and captivate our dedicated audience.Contact us now to explore advertising opportunities!",
                btnText: "Learn more",
                btnUrl: "/team"
            },
            rightPart: {
                imgUrl: "/img/Our-Token-SGT.png"
            }
        }
    ];

    return (
        <Carousel autoPlay showThumbs={false} emulateTouch={true} infiniteLoop={true} preventMovementUntilSwipeScrollTolerance={true} swipeScrollTolerance={50} interval={5000}  >

            {homeCarouselItems.map((item, i) => {
                return (
                    <div key={i}>
                        <div className='flex-box hp-carousel-item'>
                            <div className='hp-carousel-left'>
                                {item.leftPart.mobileTitle ? (
                                    <>
                                        <h2 className='desktop'>{item.leftPart.title}</h2>
                                        <h2 className='mob-900'>{item.leftPart.mobileTitle}</h2>
                                    </>
                                ) : (
                                    <h2>{item.leftPart.title}</h2>
                                )}
                                
                                <p>
                                   {item.leftPart.text} 
                                </p>
                                <a type="button" className="btn btn-primary" href={item.leftPart.btnUrl}>{item.leftPart.btnText}</a>
                            </div>
                            <div>
                                <img alt="banner-1" src={item.rightPart.imgUrl}/>
                            </div>
                        </div>
                        {/* <p className="legend">Legend 1</p> */}
                    </div>  
                )
            })}
        </Carousel>
    );
}

export default HomePageCarousel;