
import React from "react";
import { Chart } from "react-google-charts";


// https://developers.google.com/chart/interactive/docs/gallery/intervals#point-intervals
export const data = [
  [
    { type: "number", label: "x" },
    { type: "number", label: "values" },
    { id: "i0", type: "number", role: "interval" },
    { id: "i1", type: "number", role: "interval" },
    { id: "i2", type: "number", role: "interval" },
    { id: "i2", type: "number", role: "interval" },
    { id: "i2", type: "number", role: "interval" },
    { id: "i2", type: "number", role: "interval" },
  ],
  [1, 100, 90, 110, 85, 96, 104, 120],
  [2, 120, 95, 130, 90, 113, 124, 140],
  [3, 130, 105, 140, 100, 117, 133, 139],
  [4, 90, 85, 95, 85, 88, 92, 95],
  [5, 70, 74, 63, 67, 69, 70, 72],
  [6, 30, 39, 22, 21, 28, 34, 40],
  [7, 80, 77, 83, 70, 77, 85, 90],
  [8, 100, 90, 110, 85, 95, 102, 110],
];

  export const lineChartData = [
    ['Month', 'CallEfc', 'Trace', 'Peak'],
    ['01', 13.85, 166.91, 365.88],
    ['02', 54.85, 366.91, 465.88],
    ['03', 15.25, 266.91, 400.88],
    ['04', 19.66, 166.91, 365.88],
    ['05', 22.66, 198.91, 399.88],
    ['06', 45.66, 220.91, 420.88],
    ['07', 13.66, 145.91, 480.88],
    ['08', 18.66, 156.91, 320.88],
    ['01', 13.85, 166.91, 365.88],
    ['02', 54.85, 366.91, 465.88],
    ['03', 15.25, 266.91, 400.88],
    ['04', 19.66, 166.91, 365.88],
    ['05', 22.66, 198.91, 399.88],
    ['06', 45.66, 220.91, 420.88],
    ['07', 13.66, 145.91, 480.88],
  ];
  
  export const options = {
    title: "",
    curveType: "function",
    colors: ['#A0F6F3', '#A0F6F3', '#A0F6F3'],
    series: [{ color: "#A0F6F3" }],
    intervals: { style: "area" },
    //pointSize: 2,
    responsive: true,
    lineWidth: 1,
    // padding: '20px',
    // chartArea: {
    //   backgroundColor: {
    //     fill: '#A0F6F3',
    //     fillOpacity: 0.05,
    //     padding: '20px'
    //   },
    // },
    legend: "none",
    backgroundColor: 'transparent',
    vAxis: {
      gridlines: {
        count: 0
      },
      textPosition: 'none'
    },
    hAxis: {
      gridlines: {
        count: 0
      },
      gridlines: {
        color: "transparent"
      },
      textPosition: 'none'
    }
  };
  
  export function AriaIntervalChart(props) {
    let chData = [];
    if(props.chartData && props.chartData.length > 0) {
      let heading = ['Month', 'CallEfc', 'Peak', 'Trace'];
      let data = [...props.chartData];
      data[0] = heading;
      chData = data;
    }
    return (
      <Chart
        chartType="LineChart"
        //chartType="AreaChart"
        data={chData || lineChartData}
        //data={chData || data}
        options={options}
      />
    );
  }