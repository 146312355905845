import React, { useState } from "react";

const MobileTableInfo = (props) => {
    let [visible, setVisible] = useState('');
    const hintsArray = [
        {
            headerName: 'Token', 
            headerTooltip: 'The name of the cryptocurrency token being called last.',
        },
        {
            headerName: 'Buy', 
            headerTooltip: 'A link to a platform where users can purchase the mentioned token.',
        },
        {
            headerName: 'CallMktCap', 
            headerTooltip: 'The market capitalization of the token at the time of the call.',
        },
        {
            headerName: '>MktCap', 
            headerTooltip: 'The current market capitalization of the token.' ,
        },
        {
            headerName: 'CallEfc', 
            headerTooltip: 'Maximal positive  market capitalization change  of the token in the first 15 minutes after the call was made.' ,
        },
        {
            headerName: 'Trace', 
            headerTooltip: "An ongoing assessment of the token's market capitalization movement and impact since the call.",
        },
        {
            headerName: 'Peak',
            headerTooltip: "The percentage change in price from the time of the call to the token's all-time high (ATH).",
        },
        {
            headerName: 'Token Contract', 
            headerTooltip: "The unique cryptographic identifier associated with the token on the blockchain.",
        },
        {
            headerName: 'Chain', 
            headerTooltip: "Chain The blockchain on which the cryptocurrency token operates."
        },
        {
            headerName: 'Price', 
            headerTooltip: "Current price of the token in USD.",
        },
        {
            headerName: 'Volume(24)', 
            headerTooltip: "The trading volume of the token.",
        },
        // {field: 'ATX', headerName: 'ATX', resizable: true },
        { 
            headerName: 'Holders', 
            headerTooltip: "The number of token holders or addresses holding the cryptocurrency.",
        },
        {
            headerName: 'Call Description', 
            headerTooltip: "Contains the text or description of the cryptocurrency call made by the group or influencer.",
        },
        {
            headerName: 'Source', 
            headerTooltip: "Platform or channel from which the cryptocurrency call or recommendation originated.",
        },
        {
            headerName: 'Date', 
            headerTooltip: "Year/Month/Day/UTC time of the call.",
        },
    ]
    const showInfo = () => {
        if(visible === '') {
            setVisible('visible');
            visible = 'visible';
        }
        else{
            setVisible('');
            visible = '';
        }
    }
    const defaultStyles = {'display': 'inline-block', 'background': '#00c8fb', 'padding': '7px 12px', 'borderRadius': '5px', 'cursor': 'pointer', 'verticalAlign': 'top'};
    const iconStyles = props.styles ? props.styles : defaultStyles;
    return (
        <div className="mobile-table-info" onClick={showInfo} style={iconStyles}>
            <span className="info-icon">i</span>
            <div  className={`info-popup ${visible}`}>
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content">
                    <div className="modal-header">
                        <div className="modal-title h4" id="contained-modal-title-vcenter"></div>
                        <button type="button" className="btn btn-primary">X</button>
                        <button type="button" className="btn-close" aria-label="Close"></button>
                    </div>
                        {hintsArray.map((el, i) => {
                            return (
                                <div className="info-item" key={i}>
                                    <h3>{el.headerName}</h3>
                                    <div className="info-item__tooltip">{el.headerTooltip}</div>
                                </div>   
                                )
                            })}
                    </div>
                </div>
            </div>

        </div>
    )
}

export default MobileTableInfo;