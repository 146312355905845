import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { getDefaultTheme } from '../../Utils/utils';
import { createGridColumns } from '../../Utils/utils';

const ReactBootstrapModal = (props) => {
    // const additionalColumns = [
    //     {field: 'FromCalltoPeak', headerName: 'From Call to Peak'},
    // ];

    const storageName = props.isHomePage ? 'homePageColumns' : 'tokenDetailsColumns';
    const storageColumns = localStorage.getItem(storageName);
    const allCol = storageColumns && storageColumns !== 'null' ? createGridColumns(JSON.parse(storageColumns)) : props.columns;
    const additionalColumns = props.extraColumns;
    const [allColumns, setAllColumns] = useState(allCol);
    //const [emptySorage, setEmptyStorage] = useState(false);

    const updateLocalStorage = (data) => {
        let storageData = [...data];
        const filterData = storageData.map((item) => {
            // if(item.field === "BuyLink") {
            //     item.cellRenderer = 'BuyLinkHtml'
            // }
            return item.field;
        })
        const stringData = JSON.stringify(filterData)
        localStorage.setItem(storageName, stringData);
    }

    const addRemoveItem = (el) => {
        let columns = [...allColumns];
        let addColumns = [];
        columns.forEach((item, index, arr) => {
            if(item.field === el.field) {
                arr.splice(index, 1);
                addColumns.splice(index, 1);
            }
            else if(item.field !== el.field && index === arr.length - 1){
                arr.push(el);
                addColumns.push(el);
            }
        })
        setAllColumns(columns);
        
    }

    const removeItem = (el) => {
        let columns = [...allColumns];
        const storageColumns = localStorage.getItem(storageName);
        let addColumns = [];
        if(storageColumns) {
            let stColumns = JSON.parse(storageColumns);
            addColumns = stColumns;
        }
        columns.forEach((item, index, arr) => {
            if(item.field === el.field) {
                arr.splice(index, 1);
                if(storageColumns) {
                    addColumns.splice(index, 1);
                }
            }
        })
        
        //setEmptyStorage()
        setAllColumns(columns);
    }

    const onApply = () => {
        if(props.modifycolumns) {
            
            const empty = document.querySelector('.additional-list').querySelectorAll('.primary').length === 0;
            if(!empty) {
                updateLocalStorage(allColumns);
            }
            else {
                localStorage.removeItem(storageName);
            }
            props.modifycolumns(allColumns);
        }
    }

    return (
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className={getDefaultTheme() === 'light' ? 'light-modal' : 'dark-modal'}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Customize
            </Modal.Title>
            <Button onClick={props.onHide}>X</Button>
          </Modal.Header>
          <Modal.Body>
            <h4>Add or delete metrics just how you need it</h4>
            <div>
                {allColumns && allColumns.length > 0 ? (
                    <ul className='aplly-more-list'>
                        {allColumns.map((el, i) => {
                            if(el.field !== undefined) {
                                return (
                                    <li key={i}>{el.headerName}</li>
                                )
                            }
                            else{
                                return null;
                            }
                        })}
                    </ul>
                ) : null}
            </div>
            <h4>Apply more</h4>
            <div>
                {allColumns && additionalColumns && additionalColumns.length > 0 ? (
                    <ul className='aplly-more-list additional-list'>
                        {additionalColumns.map((el, i) => {
                            if(el.field !== undefined) {
                                const selected = allColumns.findIndex((e) => {
                                    return e.field ? e.field === el.field : null;
                                })
                                return (
                                    <li key={i} onClick={() => addRemoveItem(el)} className={selected !== -1 ? 'primary' : ''}>
                                        <span>
                                            {el.headerName}
                                        </span>
                                        {selected !== -1 ? (
                                            <span onClick={() => removeItem(el)} style={{'marginLeft': '10px'}}>X</span>
                                        ) : null}
                                    </li>
                                )
                            }
                            else{
                                return null;
                            }
                        })}
                    </ul>
                ) : null}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={props.onHide} variant="secondary">Cancel</Button>
            <Button onClick={onApply}>Apply</Button>
          </Modal.Footer>
        </Modal>
      );
}

export default ReactBootstrapModal;