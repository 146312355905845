import React from 'react';
import Carousel from 'react-spring-3d-carousel';
import { useState, useEffect } from "react";
import { aboutData } from '../../data/AboutData';

const Carousel3d = () => {

    const slides = () => {
        let res = [];
        if(aboutData.length > 0) {
            {aboutData.map((item, i) => {
                res.push({
                    key: i,
                    content: <div className='sg-about-item sg-outline'>
                        <div className='sg-about-item-top'>
                            <img src={item.imageUrl} alt="About image" className='img-responsive' />
                        </div>
                        
                        <div className='sg-about-item-bottom'>
                            <div>
                                <h3 className='sg-h3' style={{'textAlign': 'center'}}>{item.name}</h3>
                            </div>
                            <div>
                                <p>{item.text}</p>
                            </div>
                            <div style={{'textAlign': 'center'}}>
                                {item.buttonLink && item.buttonLink !== '' ? (
                                    <a href={item.buttonLink} className='btn btn-primary'>Read More</a>
                                ) : (
                                    <a href='' className='btn btn-primary'>Read More</a>
                                )}
                            </div>
                        </div>
                    </div>
                })
            })}
        }
        return res;
    }
    
    const table = slides().map((element, index) => {
        return { ...element, onClick: () => setGoToSlide(index) };
    });
    const [showArrows, setShowArrows] = useState(false);
    const [goToSlide, setGoToSlide] = useState(null);
    const [cards] = useState(table);

    useEffect(() => {
        //setOffsetRadius(props.offset);
        //setShowArrows(props.showArrows);
    }, []);
    
    return (
        <Carousel 
            slides={cards} 
            showArrows={true}
            goToSlide={goToSlide}
            showNavigation={true}
            offsetRadius={2}
        />
    )
}

export default Carousel3d;