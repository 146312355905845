import React, { useState } from "react";
import DesktopNav from "./DesktopNav";
import MobileNav from "./MobileNav";
import LogoAndCompName from "./UI/LogoAndCompName";
import HeaderSocialLinks from "./HeaderSocialLinks";
import { headerSocialLinksArray } from "../data/HeaderSocialLinksData";
import SearchComponent from "./UI/SearchComponent";
import ReactBootstrapSearchModal from "./UI/ReactBootstrapSearchModal";
import * as httpService from '../services/HTTPService';


const Header = () => {
    const [signalsArrray, setSignalsArrray] = useState([]);
    const [modalShow, setModalShow] = React.useState(false);

    const openModal = () => {
        if(signalsArrray.length === 0) {
            httpService.getAllSignalsSearch().then((res) => {
              if(res) {
                setSignalsArrray(res)
                //console.log(res)
                setModalShow(true);
              }
            })
        }
        else{
            setModalShow(true);
            //console.log(signalsArrray)
        }
    }
 
    return (
        <div className="header-outer">
            <div className="app-header">
                <div className="flex-box">
                    <LogoAndCompName />
                    <div className="nav-outer">
                        {/* <DesktopNav /> */}
                        <img src={'/img/social/header-social/search.svg'} alt="social-icon" className="search-img" onClick={() => openModal()} />
                        <div className="open-search" onClick={() => openModal()}>
                            <SearchComponent readOnly={true} autoFocus={false} />
                        </div>
                        <MobileNav />
                    </div>
                    <div className="header-right">
                        <HeaderSocialLinks links={headerSocialLinksArray} />
                    </div>
                </div>
            </div>
            {modalShow ? (
                <ReactBootstrapSearchModal 
                    adisableRestoreFocus
                    show={modalShow} 
                    onHide={() => setModalShow(false)}
                    signalsArrray={signalsArrray}
                    focus={true}
                />
            ) : null}
        </div>
    )
}

export default Header;