import React from 'react';
import CallsComponent from './CallsComponent';
import HomePageCarousel from './UI/Carousel';
import Carousel3d from './UI/Carousel3d';
import { aboutData } from '../data/AboutData';

const Home = () => {
    const trTokens = [
        {
            tokenName: "Token1",
            tokenPosition: "",
            tokenImg: "",
            tokenStatus: "green"
        },
        {
            tokenName: "Token2",
            tokenPosition: "",
            tokenImg: "",
            tokenStatus: "red"
        },
        {
            tokenName: "Token3",
            tokenPosition: "",
            tokenImg: "",
            tokenStatus: "green"
        },
        {
            tokenName: "Token4",
            tokenPosition: "",
            tokenImg: "",
            tokenStatus: "red"
        },
        {
            tokenName: "Token5",
            tokenPosition: "",
            tokenImg: "",
            tokenStatus: "green"
        },
        {
            tokenName: "Token6",
            tokenPosition: "",
            tokenImg: "",
            tokenStatus: "red"
        },
    ];
    const imgUrl = "/img/banner-1.jpg";

    return (
        <div className="home">
            <div className='sg-block banner'>
                <HomePageCarousel />
            </div>
            <div className='sg-block sg-trending-bock-img'>
                <img src="/img/trending-grid-full.png" alt="tranding-grid" className='img-responsive' />
                <div className='trending-calls-btn'> 
                    <a href="/calls"  className='btn btn-primary sg-button'>
                        View More
                    </a>
                    {/* <button className='btn btn-primary sg-button'>View More</button> */}
                </div>
            </div>
            {/* <div className='sg-block sg-trending-bock sg-outline'>
                <div>
                    <div className='tr-tokens flex-box'>
                        <h3>TRENDING <br />TOKENS</h3>
                        {trTokens.length > 0 ? (
                            trTokens.map((el, i) => {
                                return (
                                    <div key={i} className='tr-token-item flex-box'>
                                        <span># {i + 1}</span>
                                        {el.tokenImg && el.tokenImg !== '' ? (
                                            <img src={el.tokenImg} alt={el.tokenName} style={{borderRadius: '30px', height: '32px'}} />
                                        ) : (
                                            <div style={{borderRadius: '30px', height: '32px', width: '32px', background: "#fff"}}></div>
                                        )}
                                        <span>{el.tokenName}</span>
                                        <span className={el.tokenStatus === 'red' ? 'st-down' : 'st-up'}></span>
                                    </div>
                                )
                            })
                        ) : null}
                    </div>
                    <div className='flex-box top-tokens'>
                        <div className='grid-no-border sg-outline'>
                            <h3>TOP 5 Tokens</h3>
                            <CallsComponent /> 
                        </div>
                        <div className='grid-no-border sg-outline'>
                            <h3>TOP 5 Callers</h3>
                            <CallsComponent /> 
                        </div>
                    </div>
                    <div>
                        <div className='trending-calls'>
                            <img src="/img/trending-grid.jpg" alt="tranding-grid" />
                        </div>
                        <div className='trending-calls-btn'> 
                            <button className='btn btn-primary'>View More</button>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className='sg-block sg-about-block'>
                <div className='sg-about-top flex-box'>
                    <div>
                        <h2 className='sg-h2'>About us</h2>
                        <p>
                            Step into the crypto universe with Shillguard.com - your compass for
                            decoding signals from inﬂuencers and call groups.
                        </p>
                        <h4 className='sg-h4'>What We Do:</h4>
                        <p>
                            We gather, explore, and analyze calls made by inﬂuencers and call 
                            groups in the crypto space. Curious about who to trust in this crypto 
                            world? That's our specialty. Our indicators act like a guiding compass 
                            through the sea of calls, helping you spot the trustworthy inﬂuencers 
                            and call groups. We're here to reveal who's making honest calls, who's 
                            legit, and who might be a bit too shilly.
                        </p>
                        <div style={{marginTop: '30px'}}> 
                            <a href="/about"  className='btn btn-primary'>
                                Read More
                            </a>
                        </div>
                    </div>
                    <div>
                        <iframe 
                            style={{'boxShadow': '0px 0px 20px #00c8fb'}}
                            className='sg-outline'
                            width="100%" 
                            height="350" 
                            src="https://www.youtube.com/embed/gzQrCo_FK_w?si=ZEcX_q1YTkAJRv_w" 
                            title="SHILL GUARD WEBSITE 2.0"
                            allowFullScreen>
                        </iframe>
                    </div>
                </div>
                <div className='sg-about-bottom'>
                    {aboutData.length > 0 ? (
                        <div className='sg-about-items'>
                            {aboutData.map((item, i) => {
                                return (
                                    <div key={i} className='sg-about-item sg-outline'>
                                        <div className='sg-about-item-top'>
                                            <img src={item.imageUrl} alt="About image" className='img-responsive' />
                                        </div>
                                        
                                        <div className='sg-about-item-bottom'>
                                            <div>
                                                <h3 className='sg-h3' style={{'textAlign': 'center'}}>{item.name}</h3>
                                            </div>
                                            <div>
                                                <p>{item.text}</p>
                                            </div>
                                            <div style={{'textAlign': 'center'}}>
                                            {item.buttonLink && item.buttonLink !== '' ? (
                                            <a href={item.buttonLink} className='btn btn-primary'>Read More</a>
                                                ) : (
                                                    <a href='' className='btn btn-primary'>Read More</a>
                                            )}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    ) : null}
                </div>
                <div className='carousel_3d_out'>
                    <div className='carousel_3d'>
                        <Carousel3d />
                    </div>
                </div>
            </div>
            {/* <CallsComponent /> */}
        </div>
    )
}

export default Home;