import React, { useState, useEffect } from "react";
import * as httpService from '../services/HTTPService';
import { useLocation } from 'react-router-dom';
import ChartComponent from "./UI/StockChart";
import ReactGrid from "./React-Grid";
import { createGridColumns } from "../Utils/utils";

const CallersList = () => {
    const location = useLocation();
    const [resData, setResData] = useState({});
    const tName = window.location.href.split('/').pop();
    const decodedUrlTName = decodeURIComponent(tName);
    const searchParam  = location.state ? location.state.callGroup : decodedUrlTName;

    const CallGroupHtml = (props) => {
        let imageBase64str;
        const folderName = '/callgrimages/';
        let imgUrl = '';
        if(props.data.CallGroupImage || props.data.CryptoGroupImage || props.data.Image || props.data.TokenImage) {
            let imgSrc = props.data.CallGroupImage || props.data.CryptoGroupImage || props.data.Image || props.data.TokenImage;
            imageBase64str = imgSrc.replace(/(\r\n|\n|\r)/gm, "");
            if(imageBase64str && imageBase64str !== '' && !imageBase64str.startsWith('http')) {
                imageBase64str = "data:image/png;base64, " + imgSrc.replace(/(\r\n|\n|\r)/gm, "");
            }
            if(imageBase64str.startsWith('http')) {
                imageBase64str = imgSrc;
            }
            if(imgSrc === null || imageBase64str.startsWith('https://shillguard-001')) {
                // TOP 5 Callers
                const imgName = imgSrc.split('/').pop();
                const img = folderName + imgName;
                imageBase64str = img;
            }
        }
        
        return (
            <div>
                {props !== undefined ? (
                    <div className={props.CryptoGroupImage ? 'flex-box custom-cell rounded-img' : 'custom-cell rounded-img'} >
                        {imageBase64str? (
                            <div className="image-name">
                                <img height={46} src={imageBase64str} alt="CallGroupImage" />
                                <span>{props.data.NameOfCallGroup}</span>
                            </div>
                        ) : null}
                        {/* {props.data.Image ? (<img height={27} src={props.data.Image} alt="CallGroupImage" />) : null} */}
                    </div>
                ) : null}
            </div>
        )
    }

    const [colDefs, setColDefs] = useState([
        { field: "NameOfCallGroup", cellRenderer: CallGroupHtml, headerName: 'CallGroup' },
        { field: "DateOfCall" },
        { field: "CalledAtMarketCap" },
        { field: "CalledAtMarketCap" },
        { field: "CallEfc" },
      ]);
    const getData = () => {
        httpService.getTokenStatSearch(searchParam).then((res) => {
            if(res) {
                //console.log(res);
                setResData(res);
            }
        })
    }

    const callGroupClick = (callerName) => {
        window.location.href = `/tokens/${callerName}`;
    }


    // useEffect(() => {
    //     const chartOut = document.getElementById('chart-out');
    //     if(resData && resData.TokenChain && chartOut && chartOut.children.length === 0) {
    //         createChart();
    //     }
    // }, [resData])

    const cellClickedListener =(e) => {
        window.location.href = `/tokens/${e.data.NameOfCallGroup}`;
    }
    
    useEffect(() => {
        getData();
    }, [])
    

    return (
        <div className="token-details">
            <div>
                {resData && resData.TokenName ? (
                
                <div>
                    <div className="callers-top-info">
                        <h1>{resData.TokenName}<small> {resData.TokenSymbol}</small></h1>
                        <div className="result-list-out">
                            <div className="sg-outline">
                                <ul className='result-list'>
                                    <li>
                                        <span>Price USD:</span>
                                        <p>{resData.PriceUSD}</p>
                                    </li>
                                    <li>
                                        <span>Token Hash:</span>
                                        <p><a href={resData.TokenLink} target="_blank">{resData.TokenHash}</a></p>
                                    </li>
                                    <li>
                                        <span>At MC:</span>
                                        <p>{resData.FirstCallMarketCap}</p>
                                    </li>
                                    <li>
                                        <span>Volume:</span>
                                        <p>{resData.Volume}</p>
                                    </li>
                                    <li>
                                        <span>Total Calls:</span>
                                        <p>{resData.TotalCalls}</p>
                                    </li>
                                </ul>
                            </div>
                            <div className="sg-outline">
                                <p>
                                    This is your bio – a brief overview of your project. Share key details that you want your audience to know. 
                                    Highlight your mission, vision, or any other important information that helps define what your project is about. 
                                    Keep it concise and clear to ensure your message stands out, while also giving your audience a sense of what to expect. 
                                    Aim for a compelling summary that captures the essence of your project in under 120 words
                                </p>
                                <div className="flex-box align-right">
                                    <a type="button" className="btn btn-primary right" href="/TelegramApp">Update Bio</a>
                                </div>
                            </div>
                            <div id="chart-out">
                                {/* <ChartComponent /> */}
                            </div>
                        </div>
                    </div>
                    {/* <div className="callers-stat">
                        <h3>Best Calls</h3> 
                        <table style={{width: '100%'}} className="callers-stat-table">
                            <tbody>
                                {resData.TokenStatisticData && resData.TokenStatisticData.length > 0 ? (
                                    <>
                                        {resData.TokenStatisticData.map((item, i) => {
                                            return (
                                                <tr key={i} onClick={() => callGroupClick(item.NameOfCallGroup)}>
                                                    <td className="td-img"><span className="call-group-img"><img alt="Call group image" src={`/callgrimages/${item.CallGroupImage.split('/').pop()}`} /></span></td>
                                                    <td>{item.NameOfCallGroup} <br />{item.DateOfCall}</td>
                                                    <td>Called at <br />Reached</td>
                                                    <td>{item.CalledAtMarketCap} <br />{item.ReachedMarketCap}</td>
                                                    <td>{item.Peak}</td>
                                                </tr>
                                            )
                                        })}
                                    </>
                                ) : null}
                            </tbody>
                        </table>
                    </div> */}
                    {resData.TokenStatisticData && resData.TokenStatisticData.length > 0 ? (
                        <ReactGrid 
                            tooltipInteraction={true} 
                            columns={colDefs} 
                            sortable={true} 
                            rowData={resData.TokenStatisticData} 
                            errorMessage={''} 
                            defaultColDef={{
                                'flex': 1
                            }} 
                            cellClickedListener={cellClickedListener}
                        />
                    ) : null}
                </div>
                ) : null}
            </div>
        </div>
    )
}

export default CallersList;